import { createContext } from "preact";
export const agentCheckerDefaultContext = {
    container: undefined,
    stepOneIntro: undefined,
    stepOneDurationHtml: undefined,
    stepTwoIntro: undefined,
    stepThreeIntro: undefined,
    stepFourIntro: undefined,
    stepFiveIntro: undefined,
    stepSixIntro: undefined,
    stepSummaryIntro: undefined,
};
export const AgentCheckerCTX = createContext(agentCheckerDefaultContext);
